import React, {useState, ReactNode, useCallback} from 'react';
import {Popup} from 'antd-mobile';

export interface ButtomSheetOptions {
  /** 点击外围是否关闭 */
  backDropClose?: boolean;
  /** 从底下向上伸出的高度，如果没有则使用自动高度 */
  height?: number | string;
  zIndex?: number;
  /** 上圆角是多少 */
  topBorderRadius?: number | string;
  animationType?: 'fade' | 'none' | 'slide' | undefined;
  afterHidden?: () => void;
}

export function useBottomSheet(
  modalContent: ReactNode,
  options: ButtomSheetOptions = {},
) {
  const lastVisible = React.useRef(false);
  const [visible, setVisible] = useState<boolean>(false);
  const {backDropClose, height, topBorderRadius, zIndex, afterHidden} = options;
  const show = useCallback(() => {
    setVisible(true);
  }, []);
  const hide = useCallback(() => {
    setVisible(false);
  }, []);
  React.useEffect(() => {
    if (lastVisible.current && !visible) {
      afterHidden?.();
    }
    lastVisible.current = visible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return {
    renderModal: (
      <Popup
        visible={visible}
        closeOnMaskClick={backDropClose}
        onClose={hide}
        style={{
          height,
          borderTopLeftRadius: topBorderRadius,
          borderTopRightRadius: topBorderRadius,
          ...(zIndex
            ? {'--adm-mask-z-index': zIndex, '--z-index': zIndex + 10 + ''}
            : {}),
        }}
        bodyClassName="p-5">
        {modalContent}
      </Popup>
    ),
    show,
    hide,
  };
}
