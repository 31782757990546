import lottie, {AnimationItem} from 'lottie-web';
import React, {useRef} from 'react';
import {Modal, View, StyleSheet, Image} from 'react-native';
import theme from '@/style';
import Price from '../price';
import animationjson from '@components/assets/lottie/gold-coin.json';
import {useResponsiveDimensions} from '@/utils';
import Button from '@basicComponents/button';
import Text from '@basicComponents/text';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {Svg, G, Path} from 'react-native-svg';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import BoxShadow from '@/components/basic/shadow';
import {shareToThird, shareToChat} from '@/common-pages/bets/bets.service';
import {IShareInfo} from '@/common-pages/bets/bets.service';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import SharePanel from '@/components/business/share-panel/share-panel';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';
import {hasIM} from '@/config';

const GoldWin = ({
  visible,
  onClose,
  shareAward,
  sharInfo,
  amount = 0,
}: {
  visible?: boolean;
  amount?: number;
  shareAward?: number;
  sharInfo?: IShareInfo;
  onClose?: () => void;
}) => {
  const {shareSuccess, shareFail} = useShareToast();
  const {calcActualSize} = useScreenSize();
  const viewRef = React.useRef(null);
  const animationRef = React.useRef<AnimationItem>();
  const {width} = useResponsiveDimensions();
  const [show, setShow] = React.useState(false);
  const [time, setTime] = React.useState(hasIM ? 10 : 3);
  const panelRef = useRef<DrawerRef>(null);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time - 1);
      if (time <= 1) {
        onClose && onClose();
        setShow(false);
        clearTimeout(timer);
        setTime(hasIM ? 10 : 3);
      }
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  React.useEffect(() => {
    if (visible) {
      animationRef.current = lottie.loadAnimation({
        container: viewRef.current!,
        renderer: 'svg',
        loop: true,
        animationData: animationjson,
      });
      setTimeout(() => {
        setShow(true);
      }, 400);
      return () => {
        setTime(10);
        animationRef.current?.destroy();
      };
    } else {
      setTime(10);
      animationRef.current?.destroy();
    }
  }, [visible]);
  return (
    <>
      <Modal visible={visible} animationType="fade" transparent>
        <View style={styles.container}>
          {hasIM && (
            <NativeTouchableOpacity
              onPress={() => {
                onClose && onClose();
                setShow(false);
              }}
              style={[
                styles.closeContainer,
                {
                  right: calcActualSize(28),
                  width: calcActualSize(34),
                  height: calcActualSize(34),
                  borderRadius: calcActualSize(34),
                },
              ]}>
              <Svg
                width={calcActualSize(12)}
                height={calcActualSize(12)}
                viewBox="0 0 12 12"
                fill="none">
                <G id="Frame 1000005336">
                  <Path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7198 2.69343C11.1103 2.30291 11.1103 1.66974 10.7198 1.27922C10.3293 0.888696 9.69613 0.888698 9.3056 1.27922L5.99999 4.58485L2.69438 1.27922C2.30386 0.888698 1.67069 0.888696 1.28017 1.27922C0.889644 1.66974 0.889642 2.30291 1.28017 2.69343L4.58578 5.99907L1.28017 9.3047C0.889642 9.69523 0.889644 10.3284 1.28017 10.7189C1.67069 11.1094 2.30386 11.1094 2.69438 10.7189L5.99999 7.41328L9.3056 10.7189C9.69613 11.1094 10.3293 11.1094 10.7198 10.7189C11.1103 10.3284 11.1103 9.69523 10.7198 9.3047L7.4142 5.99907L10.7198 2.69343Z"
                    fill="white"
                  />
                </G>
              </Svg>
            </NativeTouchableOpacity>
          )}
          <View ref={viewRef} style={{width, height: width}} />
          <View
            style={[
              styles.amountContainer,
              {
                width,
                height: width,
              },
            ]}>
            <View
              style={[
                styles.amountInner,
                theme.flex.center,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  opacity: show ? 1 : 0,
                },
              ]}>
              <Price
                price={amount}
                textProps={{
                  fontSize: 24,
                  color: 'white',
                }}
              />
            </View>
          </View>
          {hasIM && (
            <View style={[styles.otherContainer]}>
              <Text
                calc
                fontSize={theme.fontSize.xs * 2}
                fontWeight="700"
                color={'white'}>
                {`${time}s`}
              </Text>
              <View
                style={[
                  theme.flex.flex,
                  theme.flex.row,
                  {
                    gap: calcActualSize(theme.paddingSize.l),
                    marginTop: calcActualSize(theme.paddingSize.s),
                  },
                ]}>
                <Button
                  buttonStyle={[
                    theme.overflow.hidden,
                    {
                      height: calcActualSize(48),
                      width: calcActualSize(114),
                      padding: calcActualSize(8),
                    },
                  ]}
                  radius={calcActualSize(4)}
                  type="linear-white"
                  onPress={() => {
                    onClose && onClose();
                    setShow(false);
                  }}>
                  <Text
                    calc
                    second
                    fontWeight="700"
                    fontSize={theme.fontSize.m}
                    blod>
                    Cancel
                  </Text>
                </Button>
                <BoxShadow
                  shadowStyle={{
                    radius: calcActualSize(4),
                    out: {x: 0, y: 1, blur: 4, color: 'rgba(0, 0, 0, 0.25)'},
                    inset: {
                      x: 0,
                      y: 1,
                      blur: 0,
                      color: 'rgba(255, 255, 255, 0.22)',
                    },
                  }}>
                  <Button
                    buttonStyle={[
                      theme.overflow.hidden,
                      theme.flex.flex,
                      theme.flex.row,
                      {
                        height: calcActualSize(48),
                        width: calcActualSize(182),
                        paddingHorizontal: calcActualSize(20),
                        paddingVertical: calcActualSize(12),
                        gap: calcActualSize(10),
                      },
                    ]}
                    radius={calcActualSize(4)}
                    type="linear-primary"
                    onPress={() => {
                      panelRef.current?.open();
                      onClose && onClose();
                      setShow(false);
                    }}>
                    {!!shareAward && (
                      <Image
                        style={{
                          width: calcActualSize(20),
                          height: calcActualSize(20),
                        }}
                        source={require('@assets/icons/arward.png')}
                      />
                    )}
                    <Text
                      calc
                      second
                      fontWeight="700"
                      color={'white'}
                      fontSize={theme.fontSize.m}
                      blod>
                      {!shareAward ? 'Share' : 'Share for Reward'}
                    </Text>
                  </Button>
                </BoxShadow>
              </View>
            </View>
          )}
        </View>
      </Modal>
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          gameType={sharInfo!.gameCode}
          hasReward={Boolean(shareAward)}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            sharInfo &&
              shareToThird(
                pt,
                sharInfo,
                (info: IShareInfo) => shareToChat(info),
                () => shareSuccess(shareAward || 0),
                shareFail,
              );
          }}
        />
      </Drawer>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  amount: {
    fontSize: 20,
  },
  amountContainer: {
    position: 'absolute',
  },
  amountInner: {
    position: 'absolute',
    bottom: '29%',
    right: 0,
    left: 0,
  },
  otherContainer: {
    position: 'absolute',
    bottom: '25%',
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeContainer: {
    position: 'absolute',
    top: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #FFF',
  },
});

export default GoldWin;
