import {CloseIcon} from '@/common-pages/proxy/svg.variable';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';

export interface BetsHeaderProps {
  title?: string;
  closeIcon?: React.ReactElement;
  onClose?: () => void;
}

const BetsHeader = ({onClose, closeIcon, title = 'Bets'}: BetsHeaderProps) => {
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.background.white,
        styles.container,
      ]}>
      <Text color={'var(--T1)'} fontFamily="fontInterBold" size="medium">
        {title}
      </Text>
      <NativeTouchableOpacity
        onPress={() => {
          onClose && onClose();
        }}>
        {closeIcon ? closeIcon : <CloseIcon width={24} height={24} />}
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 12,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

export default BetsHeader;
