import {Button, Modal} from 'antd-mobile';
import React from 'react';
import {FC} from 'react';
import {ReactComponent as PopupCloseSvg} from '@assets/svgs/popup-close.svg';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import styles from './sport-modal.module.scss';
import {ReactComponent as ArrowRoundSvg} from '@assets/svgs/arrow-round.svg';

export interface SportItem {
  bgImgUrl: string;
  defaultImgUrl: string;
  groupCode: string;
  id: number;
  moduleName: string;
  moduleService: string;
  moduleType: string;
  parentId: number;
  sort: number;
  status: number;
}

export interface SportsModalProps {
  popVisible: boolean;
  setPopVisible: React.Dispatch<React.SetStateAction<boolean>>;
  list: SportItem[];
  getSportURL: Function;
}

const SportModal: FC<SportsModalProps> = ({
  popVisible,
  setPopVisible,
  list,
  getSportURL,
}) => {
  const {t} = useTranslation();
  return (
    <Modal
      visible={popVisible}
      closeOnMaskClick
      onClose={() => setPopVisible(false)}
      bodyClassName="bg-transparent"
      content={
        <div className="bg-card w-[21.9375rem] rounded-2xl flex flex-col gap-3 p-5">
          <div className="flex flex-row items-start gap-3">
            <div className="flex flex-col flex-1">
              <span className="text-t1 font-bold text-sm">
                {t('sports.sports')}
              </span>
              <span className="text-t3 text-xs">{t('sports.selectTip')}</span>
            </div>
            <div className="size-6" onClick={() => setPopVisible(false)}>
              <PopupCloseSvg className="text-t3 size-6" />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {list.map((sport, index) => {
              return (
                <Button
                  key={index}
                  className={`rounded-lg overflow-hidden h-[12rem] p-0 ${
                    index % 2 === 0 ? styles['sports-1'] : styles['sports-2']
                  }`}
                  onClick={() => getSportURL(sport.moduleType)}>
                  <div className="flex flex-col w-full h-full justify-start items-center">
                    <Image
                      height={'8rem'}
                      width={'8rem'}
                      src={sport.bgImgUrl}
                      lazy
                    />
                    <div className="flex flex-col items-center gap-1">
                      <div className="flex flex-row items-center gap-1">
                        <Image
                          width={'1rem'}
                          height={'1rem'}
                          src={sport.defaultImgUrl}
                        />
                        <span className="font-din font-black text-white text-base">
                          {sport.moduleType.split('_')[0].toUpperCase() +
                            ' ' +
                            t('sports.sports')}
                        </span>
                      </div>
                      <Button
                        shape="rounded"
                        onClick={() => getSportURL(sport.moduleType)}
                        className={`py-1.5 px-3 text-c1 !text-sm bg-white ${styles['sport-btn']}`}>
                        <span className="font-bold">{t('sports.play')}</span>
                        <ArrowRoundSvg className="text-c1 size-3.5 -rotate-90" />
                      </Button>
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      }
    />
  );
};

export default SportModal;
