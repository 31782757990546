import Drawer from '@/components/basic/drawer';
import SharePanel from './share-panel';
import React, {useEffect, useState} from 'react';
import {DrawerRef} from '@/components/basic/drawer/drawer';
import globalStore from '@/services/global.state';
import {BasicObject} from '@/types';
import {
  IShareInfo,
  shareToChat,
  shareToThird,
} from '@/common-pages/bets/bets.service';
import {shareToPlatform} from '@/common-pages/hooks/share.hooks';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';

export type ShareInfo =
  | {
      // game 游戏相关分享
      type: 'game';
      // 游戏分享所带入的分享参数
      info?: BasicObject;
      // 分享奖励
      shareAward?: number;
    }
  | {
      // link 链接分享
      type: 'link';
      // 具体的链接
      info?: string;
      tip?: string;
    };
const SharePanelProvider = () => {
  const panelRef = React.useRef<DrawerRef>(null);
  const [shareInfo, setShareInfo] = useState<ShareInfo>();
  const {shareSuccess, shareFail} = useShareToast();
  useEffect(() => {
    const sub = globalStore.shareProviderSubject.subscribe(info => {
      setShareInfo(info);
      panelRef.current?.open();
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <Drawer
      mode="bottom"
      zIndex={11000}
      ref={panelRef}
      contentBackgroundColor="transparent">
      <SharePanel
        hasReward={Boolean(shareInfo?.type === 'game' && shareInfo.shareAward)}
        gameType={
          shareInfo?.type === 'game' ? shareInfo?.info?.gameCode : undefined
        }
        type={shareInfo?.type}
        onClose={() => panelRef.current?.close()}
        onItemPress={platform => {
          panelRef.current?.close();
          if (shareInfo?.type === 'link') {
            shareToPlatform(
              platform,
              shareInfo?.info as string,
              shareInfo?.tip,
            );
          } else {
            shareToThird(
              platform,
              shareInfo?.info as BasicObject,
              (info: IShareInfo) => shareToChat(info),
              () => {
                if (shareInfo?.shareAward && shareInfo.shareAward > 0) {
                  shareSuccess(shareInfo.shareAward);
                }
                globalStore.shareResultSubject.next(true);
              },
              () => {
                shareFail();
                globalStore.shareResultSubject.next(false);
              },
            );
          }
          // shareToThird(
          //   pt,
          //   {
          //     gameCode: gameCode,
          //     orderNo: getUUID(),
          //     threeOrderData,
          //   },
          //   (info: IShareInfo) => shareToChat(info),
          //   () => {
          //     shareSuccess(shareAward);
          //     setShareAward(0);
          //   },
          //   shareFail,
          // );
        }}
      />
    </Drawer>
  );
};

export default SharePanelProvider;
