import React from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import theme from '@/style';
import {Svg, Path} from 'react-native-svg';
import {toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';

const WonResult = ({
  awardAmount,
  style,
}: {
  awardAmount?: number;
  style?: StyleProp<ViewStyle>;
}) => {
  const {i18n} = useTranslation();
  return (
    <View style={[styles.container, theme.margin.topl, style]}>
      <Svg width={44} height={36} style={[styles.left]}>
        <Path
          d="M44.5 0H0.499996L10.9762 18L0.499996 36H44.5V0Z"
          fill="#C95300"
        />
      </Svg>
      <Svg width={44} height={36} style={[styles.right]}>
        <Path d="M0.5 0H44.5L34.0238 18L44.5 36H0.5V0Z" fill="#C95300" />
      </Svg>
      <View
        style={[
          theme.padding.lrs,
          theme.padding.tbxs,
          theme.background.primary,
          theme.flex.center,
        ]}>
        <span className="text-t1 text-xs">
          {i18n.t('game-page.label.guessRight')}
        </span>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <span className="text-t1 text-xs">
            {i18n.t('game-page.label.total')}
          </span>
          <span className="text-t1 text-xs font-bold ml-0.5">
            {toPriceStr(awardAmount)}
          </span>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingBottom: 4,
  },
  right: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  left: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
});

export default WonResult;
