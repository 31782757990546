import theme from '@style';
import React from 'react';
import {View, ViewProps, Image, StyleProp, ViewStyle} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import DownloadProgress from '@/components/business/detail-nav-title/progress';
import {BasicObject} from '@/types';
import {ReactComponent as BackSvg} from '@/assets/svgs/back.svg';
export type NavTitleProps = ViewProps & {
  /** title,如果传入了children,不会显示title */
  title?: string;
  /** 关闭按钮回调,如果不传入,不会显示关闭按钮 */
  onClose?: () => void;
  /** 返回按钮回调,如果不传入,不会显示返回按钮 */
  onBack?: () => void;
  /** 是否需要在右侧自定义内容,会显示在close(如果有)的左侧 */
  rightNode?: React.ReactNode;
  /** 是否需要在左侧自定义内容,会显示在back(如果有)的右侧 */
  leftNode?: React.ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  showProgress?: boolean;
  rate?: number;
};

const NavTitle = (props: NavTitleProps) => {
  const {
    style,
    rightNode,
    leftNode,
    children,
    containerStyle,
    title,
    showProgress = false,
    rate = 0,
    onClose,
    onBack,
    ...otherProps
  } = props;
  const zIndex = {
    zIndex: 1,
  };
  return (
    <View style={[theme.position.rel, theme.fill.fillW, zIndex, style]}>
      <View
        {...theme.shadow.defaultShadow}
        style={[
          theme.fill.fillW,
          theme.flex.center,
          theme.position.rel,
          theme.background.white,
          {
            backgroundColor: '#16181B',
            height: '3rem',
            paddingTop: 0,
          } as BasicObject,
          theme.padding.lrl,
          theme.flex.row,
          // theme.shadow.defaultShadow.style,
          containerStyle,
        ]}
        {...otherProps}>
        {onBack && (
          <NativeTouchableOpacity
            style={[
              theme.icon.l,
              theme.position.abs,
              theme.fill.fillH,
              theme.flex.center,
              zIndex,
              {
                left: theme.paddingSize.l,
              },
            ]}
            onPress={onBack}>
            <BackSvg className="size-6 text-t1" />
          </NativeTouchableOpacity>
        )}
        {leftNode && (
          <View
            style={[
              theme.position.abs,
              theme.fill.fillH,
              theme.flex.center,
              zIndex,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                left:
                  (onBack ? theme.iconSize.l + theme.paddingSize.l : 0) +
                  theme.paddingSize.l,
                zIndex: 1,
              },
            ]}>
            {leftNode}
          </View>
        )}
        {children ? (
          children
        ) : (
          <span className="max-w-[12.5rem] text-ellipsis break-all whitespace-nowrap text-t1 text-[1.125rem] font-bold">
            {title}
          </span>
        )}
        {rightNode && (
          <View
            style={[
              theme.position.abs,
              theme.flex.center,
              zIndex,

              {
                right:
                  (onClose ? theme.iconSize.l + theme.paddingSize.l : 0) +
                  theme.paddingSize.l,
                height: '3rem',
                bottom: 0,
              } as BasicObject,
            ]}>
            {rightNode}
          </View>
        )}
        {onClose && (
          <NativeTouchableOpacity
            style={[
              theme.icon.l,
              theme.position.abs,
              theme.flex.center,
              zIndex,

              {
                right: theme.paddingSize.l,
                height: '3rem',
                bottom: 0,
              } as BasicObject,
            ]}
            onPress={onClose}>
            {
              <Image
                style={theme.icon.l}
                source={require('@/assets/svgs/close.07.svg').default}
              />
            }
          </NativeTouchableOpacity>
        )}
      </View>
      {showProgress && globalStore.isAndroid && rate > 0 && (
        <DownloadProgress rate={rate} />
      )}
    </View>
  );
};
export default NavTitle;
