import {View, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import LinearGradient from '@/components/basic/linear-gradient';
import {Shadow, ShadowProps} from 'react-native-shadow-2';
import {toPriceStr, useResponsiveDimensions} from '@/utils';
export interface BetsPricesProps {
  prices?: number[];
  selectedPrice?: number;
  onSelect?: (price: number) => void;
}

const BetsPrices = ({
  prices = [],
  selectedPrice = 10,
  onSelect,
}: BetsPricesProps) => {
  const ShadowPresets = {
    button: {
      distance: 0,
      startColor: '#899CB2',
    } as ShadowProps,
  };

  const {width} = useResponsiveDimensions();

  return (
    <View style={[theme.flex.row, styles.gap]}>
      {prices.map((item, index) => {
        const isSelected = selectedPrice === item;
        return (
          <NativeTouchableOpacity
            key={index}
            onPress={() => onSelect && onSelect(item)}>
            <Shadow offset={[0, 0]} {...ShadowPresets.button}>
              <LinearGradient
                colors={
                  isSelected
                    ? [theme.basicColor.primary, theme.basicColor.primary]
                    : ['var(--button)', 'var(--button)']
                }
                style={[
                  theme.flex.center,
                  theme.borderRadius.xs,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    width:
                      (width - 40 - 8 * (prices.length - 1)) / prices.length,
                    height: 40,
                  },
                  isSelected
                    ? {}
                    : {
                        borderWidth: 1,
                        borderColor: 'var(--game-line)',
                      },
                ]}>
                <Text
                  size="medium"
                  fontFamily="fontInterBold"
                  color={'var(--T1)'}>
                  {toPriceStr(item, {
                    fixed: 0,
                  })}
                </Text>
              </LinearGradient>
            </Shadow>
          </NativeTouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  gap: {
    gap: 8,
  },
  item: {
    height: 40,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.50)',
  },
});

export default BetsPrices;
