import theme from '@/style';
import React, {ReactNode, forwardRef, useImperativeHandle} from 'react';
import {View, StyleSheet, Animated, TouchableOpacity} from 'react-native';
import Text from '@/components/basic/text';
import Dustbin from '@/components/svgs/basic/dustbin';
import NoData from '@/components/basic/error-pages/no-data';

export interface CartModalProps {
  onClose?: () => void;
  onClear?: () => void;
  children?: ReactNode;
  isEmpty?: boolean;
  headerTitle?: string | ReactNode;
  EmptyText?: string;
}

export type CartModalRef = {
  closeModal: () => void;
};

const CartModal = forwardRef(
  (
    {
      onClose,
      children,
      onClear,
      isEmpty = true,
      headerTitle = ' My BIDS',
      EmptyText = 'All empty',
    }: CartModalProps,
    ref,
  ) => {
    const transAni = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
      showHide(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      closeModal: () => showHide(false),
    }));

    const showHide = (isShow = false) => {
      Animated.timing(transAni, {
        toValue: isShow ? 1 : 0,
        duration: 240,
        useNativeDriver: true,
      }).start(() => {
        !isShow && onClose?.();
      });
    };

    return (
      <View style={[styles.container]}>
        <TouchableOpacity
          activeOpacity={1}
          style={[theme.flex.flex1]}
          onPress={() => showHide(false)}
        />
        <Animated.View
          style={[
            styles.innerContainer,
            theme.flex.col,
            {
              transform: [
                {
                  translateY: transAni.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1000, 0],
                  }),
                },
              ],
            },
          ]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.flex.centerByCol,
              theme.padding.lrl,
              theme.padding.tbl,
            ]}>
            {React.isValidElement(headerTitle) ? (
              headerTitle
            ) : (
              <Text main size="medium" fontFamily="fontInterBold">
                {headerTitle}
              </Text>
            )}
            {!isEmpty && (
              <TouchableOpacity activeOpacity={0.8} onPress={() => onClear?.()}>
                <Dustbin />
              </TouchableOpacity>
            )}
          </View>
          <View style={[theme.flex.flex1]}>
            {isEmpty ? <NoData label={EmptyText} /> : children}
          </View>
        </Animated.View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    ...theme.fill.fill,
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
    zIndex: 9999,
  },
  innerContainer: {
    height: 400,
    backgroundColor: 'var(--card)',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginBottom: 64,
  },
});

export default React.memo(CartModal);
