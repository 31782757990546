import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {useModal} from './modal.hooks';
import Text from '../text';
import {useRef, useState} from 'react';
import React from 'react';
import theme from '@style';
import {designToDp} from '@utils';
import {useTranslation} from 'react-i18next';

const {backgroundColor, borderRadiusSize, flex, font, margin, padding} = theme;

export function useConfirm(okText?: string, cancelText?: string) {
  const {i18n} = useTranslation();
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string | React.ReactElement>();
  const [hideCancel, setHideCancel] = useState<boolean>(false);
  const confirmFn = useRef<() => void>();
  const cancelFn = useRef<() => void>();
  const handleConfirm = () => {
    confirmFn.current && confirmFn.current();
    hide();
  };

  const handleCancel = () => {
    cancelFn.current && cancelFn.current();
    hide();
  };

  const styles = StyleSheet.create({
    border: {borderColor: 'var(--game-line)', borderTopWidth: 1},
    borderRight: {borderRightWidth: 1},
    btnH: {
      height: designToDp(44),
    },
    viewW: {
      width: designToDp(270),
    },
  });

  const {show, hide, renderModal} = useModal(
    <View style={[flex.col, styles.viewW]}>
      <View style={[flex.col, padding.xxl]}>
        <Text style={[font.bold, font.main, font.fl, font.center]}>
          {title}
        </Text>
        {React.isValidElement(message) ? (
          message
        ) : (
          <Text
            color={'var(--T2-dark)'}
            style={[font.fm, margin.tbl, font.center]}>
            {message}
          </Text>
        )}
      </View>
      <View style={[flex.row, styles.btnH]}>
        {!hideCancel && (
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={handleCancel}
            style={[
              flex.flex1,
              styles.border,
              flex.center,
              styles.borderRight,
            ]}>
            <Text
              color={'var(--T2-dark)'}
              style={[font.fm, {fontWeight: '500'}]}>
              {cancelText || i18n.t('label.cancel')}
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={handleConfirm}
          style={[flex.flex1, styles.border, flex.center]}>
          <Text
            style={[{color: backgroundColor.main, fontWeight: '500'}, font.fm]}>
            {okText || i18n.t('label.confirm')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>,
    {
      overlayStyle: [
        {
          padding: 0,
          borderRadius: borderRadiusSize.m + borderRadiusSize.s,
          backgroundColor: 'var(--card)',
        },
      ],
    },
  );

  const handleShow = (
    confirmTitle: string,
    confirmMessage: string | React.ReactElement,
    confirmCallback?: () => void,
    cancelCallback?: () => void,
    hideCancelBtn?: boolean | false,
  ) => {
    console.log('doconfirmshow');
    setTitle(confirmTitle);
    setMessage(confirmMessage);
    confirmFn.current = confirmCallback || (() => {});
    cancelFn.current = cancelCallback || (() => {});
    setHideCancel(Boolean(hideCancelBtn));
    show();
  };

  return {
    show: handleShow,
    hide,
    renderModal,
  };
}
