import NavTitle from '@basicComponents/nav-title';
import React, {useEffect, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import theme from '@style';
import {goCS} from '@/utils';
import globalStore from '@/services/global.state';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {NavTitleProps} from '@basicComponents/nav-title/nav-title';

import {Subject, takeUntil} from 'rxjs';
import DownloadProgress from './progress';
import {useTranslation} from 'react-i18next';
import {ReactComponent as HeadPhoneSvg} from '@assets/svgs/headphone.svg';
import Balance from '@/components/v2/business/balance/balance';
/** 这个组件的leftNode会显示在客服按钮的右侧, rightNode会显示在钱包的左侧 */
const DetailNavTitle = (
  props: NavTitleProps & {
    hideServer?: boolean;
    hideTitle?: boolean;
    /**
     * @deprecated
     * 在所有盘都更新为后台下载后,去除此属性以及对应的逻辑
     */
    showProgress?: boolean;
    hideAmount?: boolean;
    rate?: number;
    serverRight?: boolean;
  },
) => {
  const {
    title,
    rate = 0,
    hideServer,
    serverRight = false,
    showProgress = false,
    hideAmount,
    leftNode,
    rightNode,
    hideTitle = false,
    ...otherProps
  } = props;
  const {i18n} = useTranslation();
  const [login, setLogin] = useState<boolean>(false);
  const distory = new Subject<boolean>();
  useEffect(() => {
    globalStore.tokenSubject.pipe(takeUntil(distory)).subscribe(token => {
      setLogin(!!token);
    });

    return () => {
      distory.next(true);
      distory.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderService = (
    <NativeTouchableOpacity onPress={goCS}>
      <HeadPhoneSvg className="size-6 text-t1" />
    </NativeTouchableOpacity>
  );
  return (
    <View style={[theme.fill.fillW, theme.flex.col, styles.zIndexTop]}>
      <NavTitle
        leftNode={
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            {!hideServer && !serverRight && renderService}
            {leftNode && (
              <View
                style={[hideServer || serverRight ? null : theme.margin.leftl]}>
                {leftNode}
              </View>
            )}
          </View>
        }
        rightNode={
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            {rightNode && (
              <View
                style={[
                  (hideAmount || !login) && !serverRight
                    ? null
                    : theme.margin.rightl,
                ]}>
                {rightNode}
              </View>
            )}
            {serverRight && (
              <View style={[hideAmount ? null : theme.margin.rightl]}>
                {renderService}
              </View>
            )}
            {!hideAmount && <Balance />}
          </View>
        }
        title={hideTitle ? '' : title || i18n.t('loading')}
        {...otherProps}
      />
      {showProgress && globalStore.isAndroid && rate > 0 && (
        <DownloadProgress rate={rate} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  zIndexTop: {
    zIndex: 999,
  },
});

export default DetailNavTitle;
