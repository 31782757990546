import React from 'react';
import {Animated} from 'react-native';
import Text from '@/components/basic/text';
import {useResponsiveDimensions} from '@/utils';

export interface NoticeBarProps {
  text?: string;
}

const NoticeBar = ({text = ''}: NoticeBarProps) => {
  const transAnim = React.useRef(new Animated.Value(0)).current;
  const {width} = useResponsiveDimensions();
  const [textWidth, setTextWidth] = React.useState(0);

  React.useEffect(() => {
    if (textWidth) {
      startAnima();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textWidth]);

  const startAnima = () => {
    Animated.timing(transAnim, {
      toValue: 1,
      useNativeDriver: true,
      duration: 12000,
    }).start(() => {
      Animated.timing(transAnim, {
        toValue: 0,
        useNativeDriver: true,
        duration: 0,
      }).start(startAnima);
    });
  };

  return (
    <Animated.View
      onLayout={e => setTextWidth(e.nativeEvent.layout.width)}
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          position: 'absolute',
          left: 0,
          transform: [
            {
              translateX: transAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [width, -textWidth],
              }),
            },
          ],
        },
      ]}>
      <Text color={'#31373D'} numberOfLines={1} size="medium">
        {text}
      </Text>
    </Animated.View>
  );
};

export default NoticeBar;
