import React from 'react';
import {StyleSheet, View} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import {SafeAny} from '@/types';
import BackgroundTimer from '@components/utils/timer';
import globalStore from '@/services/global.state';
import CutDown from './cut-down';
import Video from '@/components/basic/video';

export interface LotteryTimeCutdownProps {
  remainingTime?: number;
  tip?: string;
  includeHour?: boolean;
  hasCutdown?: boolean;
  onTimeEnd?: () => void;
  onDisabled?: () => void;
  isSame?: boolean;
  stopBetSec?: number;
  onTimeChange?: (v: number) => void;
  timeVersion?: number;
  isLive?: boolean;
}
const LotteryTimeCutdown = ({
  remainingTime,
  onTimeChange,
  timeVersion = 0,
  stopBetSec,
  hasCutdown,
  onDisabled,
  isSame,
  onTimeEnd,
  isLive,
}: LotteryTimeCutdownProps) => {
  const {i18n} = useTranslation();
  const timer = React.useRef<SafeAny>(null);
  const timeCurrent = React.useRef(0);
  const [showCutdown, setShowCutdown] = React.useState(false);
  const [cutdownPaused, setCutdownPaused] = React.useState(true);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [nowTime, setNowTime] = React.useState(-1);
  const lockToast = React.useRef(false);
  const lockShow = React.useRef(false);
  const timeRef = React.useRef(-1);

  const timeStr = React.useMemo(() => {
    if (nowTime > 0) {
      const days = Math.floor(nowTime / 3600 / 24);
      const hour = Math.floor(nowTime / 3600) % 60;
      const minutes = Math.floor(nowTime / 60) % 60;
      const seconds = nowTime % 60;
      return {
        days,
        hour: (hour + '').padStart(2, '0').split(''),
        minutes: (minutes + '').padStart(2, '0').split(''),
        seconds: (seconds + '').padStart(2, '0').split(''),
      };
    }
    return {
      days: 0,
      hour: ['-', '-'],
      minutes: ['-', '-'],
      seconds: ['-', '-'],
    };
  }, [nowTime]);

  React.useEffect(() => {
    timer.current = BackgroundTimer.setInterval(() => {
      timeCurrent.current += 1;
      setCurrentTime(timeCurrent.current);
    }, 1000);
    return () => timer.current && BackgroundTimer.clearInterval(timer.current);
  }, []);

  React.useEffect(() => {
    if (timeRef.current > 0) {
      timeRef.current -= 1;
      setNowTime(timeRef.current);
      onTimeChange?.(timeRef.current);
      if (timeRef.current === 3 && !lockShow.current && hasCutdown) {
        // 倒计时3秒
        lockShow.current = true;
        if (isSame) {
          setCutdownPaused(false);
          setShowCutdown(true);
        }
      }
      if (timeRef.current === 0) {
        onTimeEnd?.();
      }
      if (stopBetSec && stopBetSec > 0 && timeRef.current <= stopBetSec) {
        if (!lockToast.current) {
          lockToast.current = true;
          if (isSame && !isLive) {
            globalStore.globalWaringTotal(
              i18n.t('game-page.tip.unable', {
                time: stopBetSec,
              }),
            );
          }
          onDisabled?.();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  React.useEffect(() => {
    if (timeVersion && timeVersion > 0 && remainingTime && remainingTime > 0) {
      timeRef.current = remainingTime + 1;
    }
    return () => {
      lockToast.current = false;
      lockShow.current = false;
    };
  }, [remainingTime, timeVersion]);

  return (
    <View style={[theme.flex.row, theme.flex.alignEnd]}>
      {timeStr.days > 0 && (
        <Text
          style={[theme.margin.rights]}
          color={theme.basicColor.dark}
          fontFamily="fontDinBold"
          fontSize={20}>
          {timeStr.days}
          <Text color={theme.basicColor.dark} fontFamily="fontDinBold">
            Day
          </Text>
        </Text>
      )}

      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.xxs / 2},
        ]}>
        <View style={styles.itemContainer}>
          <Text size="large" fontFamily="fontDinBold" color={'white'}>
            {timeStr.hour}
          </Text>
        </View>
        <Text
          fontFamily="fontDinBold"
          size="large"
          color={theme.basicColor.dark}>
          :
        </Text>
        <View style={styles.itemContainer}>
          <Text size="large" fontFamily="fontDinBold" color={'white'}>
            {timeStr.minutes}
          </Text>
        </View>
        <Text
          fontFamily="fontDinBold"
          size="large"
          color={theme.basicColor.dark}>
          :
        </Text>
        <View style={styles.itemContainer}>
          <Text size="large" fontFamily="fontDinBold" color={'white'}>
            {timeStr.seconds}
          </Text>
        </View>
      </View>
      {hasCutdown && (
        <Video
          onEnd={() => {
            setCutdownPaused(true);
            // videoRef.current?.seek(0);
          }}
          audioOnly
          source={require('@components/assets/music/cut-down.mp3')}
          src={'/assets/mp3/cut-down.mp3'}
          paused={cutdownPaused}
        />
      )}

      {hasCutdown && (
        <CutDown
          visible={showCutdown}
          onClose={() => {
            setShowCutdown(false);
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    width: 26,
    height: 28,
    backgroundColor: theme.basicColor.dark,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
});

export default LotteryTimeCutdown;
