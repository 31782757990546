import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet, Image, StyleProp, ViewStyle} from 'react-native';
import Text from '@/components/basic/text';
import LinearGradient from '@/components/basic/linear-gradient';
interface TimesProps {
  configId?: number | null;
  minutes: {cycle?: number; id?: number; time?: string}[];
  onTimePress?: (id?: number) => void;
  itemStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  checkColors?: string[];
  selectInnerStyle?: StyleProp<ViewStyle>;
}

const Times = ({
  configId,
  minutes = [],
  onTimePress = () => {},
  itemStyle = {},
  style = {},
  selectInnerStyle,
  checkColors = theme.backgroundColor.timeLinearGradient,
}: TimesProps) => {
  return (
    <View
      style={[
        theme.margin.s,
        theme.flex.row,
        styles.container,
        {gap: theme.paddingSize.s},
        style,
      ]}>
      {minutes.map((item, _index) => (
        <NativeTouchableOpacity
          onPress={() => onTimePress(item?.id)}
          key={_index}
          style={[
            theme.flex.flex1,
            {backgroundColor: 'var(--image)'},
            theme.borderRadius.xs,
            theme.flex.center,
            styles.item,
            itemStyle,
          ]}>
          {configId === item.id && (
            <View style={[theme.position.abs, styles.selected]}>
              <LinearGradient
                colors={checkColors}
                style={[
                  theme.flex.flex1,
                  styles.selectedInner,
                  selectInnerStyle,
                ]}
              />
            </View>
          )}
          <Image
            resizeMode="cover"
            style={[styles.clock]}
            source={require('@components/assets/imgs/game/color/clock.webp')}
          />
          <Text
            color={configId === item.id ? 'var(--T1)' : 'var(--T2-dark)'}
            fontFamily="fontDinBold"
            size="medium">
            {item.cycle}min
          </Text>
        </NativeTouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 59,
  },
  item: {
    borderTopRightRadius: 16,
    overflow: 'hidden',
    height: 59,
  },
  clock: {
    width: 21,
    marginBottom: 2,
    height: 24,
  },
  selected: {
    paddingTop: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: theme.basicColor.primary,
  },
  selectedInner: {
    borderTopRightRadius: 16,
    borderRadius: 4,
  },
});

export default React.memo(Times);
