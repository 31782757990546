import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {BasicObject} from '@/types';
import React from 'react';
import {View, Image, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import Text from '@/components/basic/text';

export interface TimesItemProps {
  onTimePress?: (id?: number) => void;
  active?: boolean;
  label?: string;
  itemStyle?: StyleProp<ViewStyle>;
  innerStyle?: StyleProp<ViewStyle>;
}

const TimesItem = ({
  timeInfo = {},
  onTimePress = () => {},
  itemStyle,
  active,
  innerStyle = {},
  label = '',
}: {
  timeInfo?: BasicObject;
} & TimesItemProps) => {
  return (
    <NativeTouchableOpacity
      onPress={() => onTimePress(timeInfo?.id)}
      style={[
        theme.flex.flex1,
        {backgroundColor: 'var(--image)'},
        theme.borderRadius.xs,
        theme.flex.center,
        styles.item,
        itemStyle,
      ]}>
      {active && (
        <View style={[theme.position.abs, styles.selected]}>
          <LinearGradient
            colors={theme.backgroundColor.timeLinearGradient}
            style={[theme.flex.flex1, styles.selectedInner, innerStyle]}
          />
        </View>
      )}
      <Image
        resizeMode="cover"
        style={[styles.clock]}
        source={require('@components/assets/imgs/game/color/clock.webp')}
      />
      <Text
        color={active ? 'var(--T1)' : 'var(--T2-dark)'}
        fontFamily="fontDinBold"
        size="medium">
        {label}
      </Text>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    borderTopRightRadius: 16,
    overflow: 'hidden',
    height: 59,
  },
  clock: {
    width: 21,
    marginBottom: 2,
    height: 24,
  },
  selected: {
    paddingTop: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: theme.basicColor.primary,
  },
  selectedInner: {
    borderTopRightRadius: 16,
    borderRadius: 4,
  },
});

export default React.memo(TimesItem);
